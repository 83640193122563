body {
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter',
    'Lucida Typewriter', monospace;
  font-size: 0.95em;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
}

h2 {
  font-size: 1rem;
  margin-bottom: 0;
}

p {
  margin-top: 0.2em;
  margin-bottom: 1em;
}

ul {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

a {
  color: black;
}

ul.no-marker {
  list-style-type: none;
  padding-left: 0;
}

header {
  margin: 2rem;
}

header img {
  display: inline-block;
  height: auto;
  max-height: 48px;
  max-width: 377px;
  width: auto;
}

header a {
  text-decoration: none;
}

header nav {
  text-align: right;
}

header a.active {
  font-weight: 800;
}

.building {
  fill-rule: evenodd;
}

img.suprastanza {
  height: auto;
  padding: 16px 0;
  width: 90%;
}

@media only screen and (max-width: 1024px) {
  header img {
    display: block;
  }

  nav {
    margin-top: 1.2rem;
  }

  h2 {
    margin-top: 2rem;
  }

  .content-container {
    margin: 2rem;
  }

  #logo-selector svg {
    height: 220px;
    margin: 0 auto;
    display: block;
    width: auto;
  }
}

@media only screen and (min-width: 1024.1px) {
  header nav {
    float: right;
  }

  article {
    opacity: 0;
  }

  article.visible,
  article.hover {
    opacity: 1;
  }

  .content-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 0.8fr 1.2fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'left-1 image right-1-2'
      'left-2 image right-1-2'
      'left-3 below-image right-3';
    margin: 0 2rem;
  }

  #news {
    grid-area: left-1;
    margin-left: 15%;
  }

  #projects {
    grid-area: left-2;
    margin-right: 25%;
  }

  #board {
    grid-area: left-3;
    margin-left: 15%;
  }

  #logo-selector {
    align-self: center;
    grid-area: image;
    margin-top: 40px;
  }

  .building-group {
    opacity: 0.2;
  }

  .building-group:hover {
    cursor: pointer;
  }

  .building-group.visible {
    opacity: 1;
  }

  #sponsors {
    grid-area: below-image;
    margin-top: 4rem;
  }

  #purpose {
    grid-area: right-1-2;
    margin-left: 10%;
  }

  #contact {
    grid-area: right-3;
  }
}
