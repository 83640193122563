body {
  max-width: 1400px;
  box-sizing: border-box;
  margin: 0 auto;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
  font-size: .95em;
}

h2 {
  margin-bottom: 0;
  font-size: 1rem;
}

p {
  margin-top: .2em;
  margin-bottom: 1em;
}

ul {
  margin-top: .2em;
  margin-bottom: .5em;
}

a {
  color: #000;
}

ul.no-marker {
  padding-left: 0;
  list-style-type: none;
}

header {
  margin: 2rem;
}

header img {
  height: auto;
  max-height: 48px;
  max-width: 377px;
  width: auto;
  display: inline-block;
}

header a {
  text-decoration: none;
}

header nav {
  text-align: right;
}

header a.active {
  font-weight: 800;
}

.building {
  fill-rule: evenodd;
}

img.suprastanza {
  height: auto;
  width: 90%;
  padding: 16px 0;
}

@media only screen and (max-width: 1024px) {
  header img {
    display: block;
  }

  nav {
    margin-top: 1.2rem;
  }

  h2 {
    margin-top: 2rem;
  }

  .content-container {
    margin: 2rem;
  }

  #logo-selector svg {
    height: 220px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-width: 1024.1px) {
  header nav {
    float: right;
  }

  article {
    opacity: 0;
  }

  article.visible, article.hover {
    opacity: 1;
  }

  .content-container {
    grid-template: "left-1 image right-1-2"
                   "left-2 image right-1-2"
                   "left-3 below-image right-3"
                   / 1fr .8fr 1.2fr;
    gap: 1.5rem;
    margin: 0 2rem;
    display: grid;
  }

  #news {
    grid-area: left-1;
    margin-left: 15%;
  }

  #projects {
    grid-area: left-2;
    margin-right: 25%;
  }

  #board {
    grid-area: left-3;
    margin-left: 15%;
  }

  #logo-selector {
    grid-area: image;
    align-self: center;
    margin-top: 40px;
  }

  .building-group {
    opacity: .2;
  }

  .building-group:hover {
    cursor: pointer;
  }

  .building-group.visible {
    opacity: 1;
  }

  #sponsors {
    grid-area: below-image;
    margin-top: 4rem;
  }

  #purpose {
    grid-area: right-1-2;
    margin-left: 10%;
  }

  #contact {
    grid-area: right-3;
  }
}

/*# sourceMappingURL=index.ab03de27.css.map */
